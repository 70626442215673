<template>
  <header class="d-flex flex-wrap justify-content-center p-3 border-bottom shadow-sm">
    <router-link to="/" class="d-flex align-items-center me-auto text-dark text-decoration-none">
      <span class="fs-4">Notify</span>
    </router-link>

    <ul class="nav nav-pills">
      <li class="nav-item">
        <router-link to="/" class="nav-link">User</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/send" class="nav-link">Send</router-link>
      </li>
    </ul>
  </header>
</template>