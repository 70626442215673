<template>
  <the-header></the-header>

  <div class="container py-3">
    <router-view></router-view>
  </div>
</template>

<script setup>

import TheHeader from "@/components/layouts/TheHeader";
//import {inject} from "vue";
import { provide } from "vue";
import {useStore} from "vuex";

import firebaseMessaging from '@/firebase.js'
provide('messaging', firebaseMessaging);


const store = useStore();


const loadCoaches = async (refresh = false) => {
  //this.isLoading = true;
  try {
    await store.dispatch('loadUsers', {forceRefresh: refresh});
  } catch(e){
    //this.error = e.message || "Something went wrong!";
  }
  //this.isLoading = false;
};


loadCoaches(true);

import NoSleep from 'nosleep.js';
const noSleep = new NoSleep();
noSleep.enable();

const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    noSleep.enable();
  }
};

document.addEventListener('visibilitychange', handleVisibilityChange);

</script>

<style>

</style>
