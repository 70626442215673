<template>
  <base-card title="Send notification">
    <form @submit.prevent="sendNotification">
    <div class="row mb-3">
      <div class="col">
        <select class="form-select" v-model="userInput">
          <option v-for="user in store.getters.users" :key="user.id" :value="user.token">{{ user.id }}</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <input class="form-control" type="text" v-model="titleInput" placeholder="Title"/>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <textarea class="form-control" rows="5" v-model="messageInput" placeholder="Message"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col text-end">
        <button class="btn btn-primary">Send</button>
      </div>
    </div>
    </form>
  </base-card>
</template>

<script setup>
import {ref} from "vue";
import {useStore} from "vuex";

const store = useStore();

const userInput = ref(null);
const titleInput = ref(null);
const messageInput = ref(null);

const sendNotification = async () => {
  console.log(userInput.value);
  console.log(titleInput.value);
  console.log(JSON.stringify(messageInput.value));

  var key = 'AAAAgUfz6Do:APA91bEhntpdTTtlzgK5zrfvmR5eh4KtYkpK0RkbwjmWZYsWWhJWqxXwu1wueV60qd9uaUuZlfi_ulzOVK5qR8VJVj10MMV5drd4nzgjmqBEcDQBSK7pi07xkTX89WjM8AYPbDIobwrN';
  var to = userInput.value;
  var notification = {
    'title': titleInput.value,
    'body': messageInput.value,
    //'icon': 'firebase-logo.png',
    //'click_action': 'http://localhost:8081'
  };

  fetch('https://fcm.googleapis.com/fcm/send', {
    'method': 'POST',
    'headers': {
      'Authorization': 'key=' + key,
      'Content-Type': 'application/json'
    },
    'body': JSON.stringify({
      'notification': notification,
      'to': to
    })
  }).then(function(response) {
    console.log(response);
  }).catch(function(error) {
    console.error(error);
  })

  // if(!response.ok){
  //   //error
  //   console.log(response);
  // }

  // const responseData = await response.json();
  //
  // console.log(responseData);

  userInput.value = null;
  titleInput.value = null;
  messageInput.value = null;
};

</script>

<style scoped>

</style>