import { createRouter, createWebHistory } from 'vue-router';
import UserDetails from "@/pages/UserDetails";
import SendNotification from "@/pages/SendNotification";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: UserDetails
        },
        {
            path: '/send',
            component: SendNotification
        },
        {
            path: '/notFound(.*)',
            redirect: '/'
        }
    ],
    linkActiveClass: 'active',
    linkExactActiveClass: 'active'
});

export default router;