//firebase.js

import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCaWDz9AdPrbM9oE9a2uJWTm-0fO7lWVq8",
    authDomain: "notify-4ea3f.firebaseapp.com",
    databaseURL: "https://notify-4ea3f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "notify-4ea3f",
    storageBucket: "notify-4ea3f.appspot.com",
    messagingSenderId: "555257948218",
    appId: "1:555257948218:web:e43e6d5f54cc160232ad03"
};

// Initialize Firebase
const fbApp = initializeApp(firebaseConfig);
const fbMessaging = getMessaging(fbApp);

onMessage(fbMessaging, (payload) => {
    console.log('Message received. ', payload);
    // ...

    // @todo podwójne notyfikacje?
    // try {
    //     navigator.serviceWorker.getRegistration()
    //         .then((reg) => reg.showNotification(payload.notification.title, {
    //             body: payload.notification.body,
    //             sound:"default"
    //         }))
    //         .catch((err) => alert('Service Worker registration error: ' + err));
    // } catch (err) {
    //     alert('Notification API error: ' + err);
    // }
});

export default fbMessaging;