import { createStore } from "vuex";

const store = createStore({
    state(){
        return {
            currentUserId: "",
            usersList: []
        }
    },
    mutations: {
        registerUser(state, payload){
            state.currentUserId = payload.id;
            state.usersList.push(payload);
        },
        setUsers(state, payload){
            state.usersList = payload;
        },
        setUser(state, payload){
            state.currentUserId = payload.id;
        },
    },
    actions: {
        async registerUser(context, data){
            const userData = {
                token: data.token
            }

            const response = await fetch('https://notify-4ea3f-default-rtdb.europe-west1.firebasedatabase.app/users.json', {
                method: 'POST',
                body: JSON.stringify(userData)
            });

            if(!response.ok){
                //error
            }

            const responseData = await response.json();

            //console.log(responseData.name);
            localStorage['currentUserId'] = responseData.name;

            context.commit('registerUser', {
                ...userData,
                id: responseData.name
            });
        },

        async loadUsers(context){//context, payload
            // if(!payload.forceRefresh && !context.getters.shouldUpdate){
            //     return;
            // }

            const response = await fetch(`https://notify-4ea3f-default-rtdb.europe-west1.firebasedatabase.app/users.json`);
            const responseData = await response.json();

            if(!response.ok){
                // const error = new Error(responseData.message || 'Faild to fetch!');
                // throw error;
            }

            const users = [];

            for(const key in responseData){
                const user = {
                    id: key,
                    token: responseData[key].token
                };

                users.push(user);
            }

            context.commit('setUsers',users);
            //context.commit('setFetchTimestamp');
            if(localStorage['currentUserId']){
                context.commit('setUser',{id:localStorage['currentUserId']});
            }
        }
    },
    getters: {
        users(state){
            return state.usersList;
        },
        user(state){
            const user = state.usersList.find(usr => usr.id === state.currentUserId);
            return user;
        },
        isRegister(state){
            return state.currentUserId?true:false;
        },
    }
});

export default store;