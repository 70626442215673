<template>
  <base-card title="User">
    <div class="text-center" v-if="isRegister">
      <p>You are registered as:</p>
      <p>id: {{userId}}</p>
      <p>token: {{userToken}}</p>
    </div>
    <div class="text-center" v-else>
      <p>You are not registered</p>
      <button class="btn btn-primary" @click="allowNotifivation">Allow to notify</button>
    </div>
  </base-card>
</template>

<script setup>
  import {computed,inject} from "vue";
  import {useStore} from "vuex";

  const store = useStore();

  const isRegister = computed( () => {
    return store.getters.isRegister;
  });

  const userId = computed(()=>{
    const user = store.getters.user;
    return user?store.getters.user.id:null;
  });
  const userToken = computed(()=>{
    const user = store.getters.user;
    return user?store.getters.user.token:null;
  });

  import { getToken } from "firebase/messaging";
  const messaging = inject('messaging');

  const allowNotifivation = () => {

      // Notification.requestPermission().then((permission) => {
      //   if (permission === 'granted') {
      //     console.log('Notification permission granted.');
      //
      //     if ('serviceWorker' in navigator) {
      //       navigator.serviceWorker.ready.then(function(reg) {
      //
      //         reg.pushManager.subscribe({
      //           userVisibleOnly: true
      //         }).then(function(sub) {
      //           console.log('Endpoint URL: ', sub.endpoint);
      //           console.log(sub);
      //           // {
      //           //     endpoint: "https://fcm.googleapis.com/fcm/send/eS19xEJSQZI:AP…1tEsZCRRoChsJjZDBoXgKMxAtOTveQfXg9dPksBQC9Mnyd5UI",
      //           //     expirationTime: null,
      //           //     options: PushSubscriptionOptions
      //           // }
      //
      //           // //save to file
      //
      //           store.dispatch('registerUser', {token: sub.endpoint});
      //
      //
      //
      //         }).catch(function(e) {
      //           if (Notification.permission === 'denied') {
      //             console.warn('Permission for notifications was denied');
      //           } else {
      //             console.error('Unable to subscribe to push', e);
      //           }
      //         });
      //       })
      //     }
      //
      //   }
      // });

    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');

        getToken(messaging, {vapidKey: "BLJkB36MVVxemhdQzNc9tDrscCWZo0nMc_wtivnHweKmOR-exL6A7sBrZ7EaOG-KJf8u1Os7dJ3VaUzW_mC4sfM"}).then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log(currentToken);
            store.dispatch('registerUser', {token: currentToken});
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
      }
    });

    // const currentToken = messaging.getToken({ vapidKey: "BPRPB7rw1Ayy6nyRf9UH87uS12_YJkrIxkoEtYkV5GFhlfgy2na64BCx0EvKW3SqVcL0h7GtN7zaGEfsNUBBCis" });
    //
    // console.log(currentToken);
    //store.dispatch('registerUser', {token: sub.endpoint});

  };
</script>