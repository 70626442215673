import 'bootstrap/dist/css/bootstrap.min.css'

import { createApp } from 'vue'
import App from './App.vue'
import BaseCard from "@/components/UI/BaseCard";

import router from "@/router";
import store from "@/store";

const app = createApp(App);

app.component('base-card', BaseCard);

app.use(router);
app.use(store)

app.mount('#app');

import 'bootstrap/dist/js/bootstrap.min'
import './registerServiceWorker'
